<template>
	<div class="product-card">
		<router-link :to="Links">
			<div class="product-card-image center-item">
				<img :src="data.primary_image" alt="" />
			</div>
			<div class="product-card-name center-item">{{ data.name }}</div>
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		data: Object,
		links: { default: null },
	},
	computed: {
		Links() {
			return !this.links
				? { name: "view-product", params: { id: this.data.id } }
				: this.links;
		},
	},
};
</script>

<style lang="scss">
@import "../scss/shared-vue.scss";

.product-card {
	width: 18rem;
	height: 24rem;
	background: $color-light-1;
	border-radius: 1rem;
	margin: auto;
	transition: all 400ms;
	overflow: hidden;
	&-image {
		width: 100%;
		height: 75%;
		transition: all 400ms;
		img {
			height: 95%;
		}
	}
	&-name {
		margin: auto;
		border-top: 1px solid #e3e0e1;
		width: 100%;
		height: 20%;
		font-size: 1rem;
		padding: 0 5px;
		text-align: center;
		flex-direction: column;
		@media (max-width: $md-size) {
			font-size: 0.9rem;
		}
		@media (min-width: $hd-size) {
			font-size: 1.05rem;
		}
	}
}
.product-card:hover {
	box-shadow: 0 0 13px #999;
	scale: 1.01;

	.product-card-image {
		transition-delay: 0.7s;
		height: 100%;
	}
}
</style>