
<template>
    <div>
        <header class="about-us-header position-relative">
            <!-- jumbotron -->
			<div class="jumbotron">
				<div class="container">
					<div class="jumbotron__content">
						<h1 class="jumbotron__heading heading-1">
							News
						</h1>

						<p class="jumbotron__descreption">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi explicabo perspiciatis ipsa repudiandae, velit omnis nostrum quisquam atque labore sapiente fuga iusto eius beatae voluptatibus! Nesciunt soluta optio error tenetur.
						</p>
					</div>
				</div>
			</div>
            <svg class="svg-of-header" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 143.7" xml:space="preserve">
				<path class="st0" d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5" fill="#FFFFFF"/>
			</svg>
        </header>

        <main class="mb-5">
            <section>
                <h2 class="heading-2 text-center mb-4">
                    news
                </h2>

                <div class="container">
                    <div class="row">
                        <div
                          class="col-xl-3 col-lg-4 col-sm-6 mb-xl-0 mb-4 d-flex justify-content-center"
                          v-for="index in news"
                          :key="index.id"
                        >
                            <news-card :image="index.image" :date="index.date" :id="index.id">
                               Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi labore distinctio doloremque?
                            </news-card>
                       </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '../../store';
import NewsCard from '../../components/NewsCard'

export default {
    data(){
        return {
            news:[
                {
                    id:0,
                    date: '01/02/2020',
                    image: 'img/news-1.jpg'
                },
                {
                    id:1,
                    date: '21/03/2020',
                    image: 'img/news-2.jpg'
                },
                {
                    id:2,
                    date: '07/08/2021',
                    image: 'img/news-3.jpg'
                },
                {
                    id:3,
                    date: '07/03/2021',
                    image: 'img/news-1.jpg'
                },
            ]
        }
    },

    components:{
        NewsCard,
    },
    computed: {
        ...mapState({
            // news: state => state.news.news
        })
    },

    //  TODO: remove //(comment) from next lines
    // async beforeRouteEnter(to, from, next) {
    //     await store.dispatch('news/fetchAll');
    //     next();
    // }
}
</script>
<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";

// Next sass variables all what you need to change main css properties
$jumbotron-side-image: '/img/news.jpg';
$jumbotron-side-image-small: '/img/news-small.jpg';

@include jumbotron-side-style($jumbotron-side-image, $jumbotron-side-image-small);
</style>