import * as types from '../mutation-types';
import { unionState } from '../../helpers';

const state = () => ({
	albums: [],
});

const actions = {
    fetchAll({ commit }) {
        return axios.get('/albums').then(response => {
			commit(types.STORE_ALBUMS, response.data);
		});
	},

	fetch({ commit }, { id }) {
        return axios.get(`/albums/${id}`).then(response => {
			commit(types.STORE_ONE_ALBUM, response.data);
		});
    },
};

const mutations = {
	[types.STORE_ALBUMS](state, albums) {
		state.albums = albums;
	},

	[types.STORE_ONE_ALBUM](state, album) {
		state.albums = unionState(state.albums, [album]);
	}
};

const getters = {
	getById: state => id => state.albums.find(t => t.id === id),
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};