<template>
	<div>
		<header class="position-relative" id="header">
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>

		<main>
			<div class="container my-5">
				<breadcrumbs
					class="mb-4"
					:links="[
						{
							name: $t('navbar.home'),
							path: '/',
						},
						{ name: $t('navbar.products'), path: '/products', active: true },
					]"
				></breadcrumbs>
				<h1 class="heading-1 text-center mb-5 text-uppercase">
					{{ $t("page.all-products") }}
				</h1>
				<loader
					v-if="loading"
					style="height: 50vh"
					class="d-flex justify-content-center align-content-center flex-column"
				></loader>
				<div class="row" v-else>
					<div
						class="col-lg-3 col-sm-6 col-12 mb-3"
						v-for="product in products"
						:key="product.id"
					>
						<product-card :data="product"> </product-card>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { mapState } from "vuex";
import ProductCard from "../../components/ProductCard.vue";
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import Loader from "../../components/Loader.vue";

export default {
	components: {
		ProductCard,
		Breadcrumbs,
		Loader,
	},
	computed: {
		...mapState({
			products: (state) => state.products.products,
		}),
	},
	data() {
		return {
			loading: true,
		};
	},
	methods: {
		fetchProducts() {
			this.$store
				.dispatch("products/fetchAll")
				.then()
				.finally(() => {
					this.loading = false;
				});
		},
	},
	created() {
		if (this.products.length === 0) this.fetchProducts();
		else this.loading = false;
	},
	//  TODO: remove //(comment) from next lines
	// async beforeRouteEnter(to, from, next) {
	//     await store.dispatch('products/fetchAll');
	//     next();
	// }
};
</script>

<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";

#header {
	background: linear-gradient(to left, #b8c2c6, #bac4c6);
	height: 220px;
	@media (min-width: $hd-size) {
		height: 300px;
	}
}
.product-card {
	width: 100%;
	height: 20rem;
	margin-bottom: 1.5rem;
	@media (max-width: $lg-size) {
		width: 18rem;
		height: 23rem;
	}
	@media (max-width: $md-size) {
		width: 16rem;
		height: 20rem;
	}
}
</style>