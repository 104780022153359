<template>
	<div class="centered" dir="ltr">
		<div class="blob-1"></div>
		<div class="blob-2"></div>
		<div class="blob-1"></div>
		<div class="blob-2"></div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "../scss/shared-vue.scss";

.centered {
	position: relative;
	width: 180px;
	margin: 0 auto;
}
.blob-1,
.blob-2 {
	width: 70px;
	height: 12px;
	background: $color-secondary;
	border-radius: 10px;
	margin-bottom: 1px;
}
.blob-1 {
	animation: osc-l 1s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite alternate;
}
.blob-2 {
	animation: osc-r 1s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite alternate;
	background: $color-primary;
}
@keyframes osc-l {
	0% {
		margin-left: 0px;
	}
	100% {
		margin-left: calc(180px - 70px);
	}
}
@keyframes osc-r {
	0% {
		margin-left: calc(180px - 70px);
	}
	100% {
		margin-left: 0px;
	}
}
</style>