<template>
	<!--
        TODO: delete this 
        <div
        id="nav"
        class="py-3 back-color-primary"
        :class="[
            {'bg-transparent': collpased && this.$route.name === 'home'},
            {'nav-home': this.$route.name === 'home'},
        ]">
    -->
	<div id="nav" class="nav-top" :class="{ 'back-color-primary': !collpased }">
		<!-- navbar -->
		<nav class="navbar navbar-expand-lg bg-transparent">
			<div class="container pt-2">
				<router-link :to="{ name: 'home' }" class="navbar-brand fs-3">
					<img src="/Logo/Logo.png" alt="" class="navbar-brand-image" />
				</router-link>

				<button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbar"
					aria-controls="navbar"
					aria-expanded="false"
					aria-label="Toggle navigation"
					@click="collpased = !collpased"
				>
					<span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
				</button>

				<div class="collapse navbar-collapse" id="navbar">
					<ul class="navbar-nav mb-2 mb-lg-0 align-items-between">
						<li class="nav-item">
							<router-link
								:to="{ name: 'home' }"
								class="nav-link pt-md-3"
								:class="{ selected: $route.name == 'home' }"
								>{{ $t("navbar.home") }}</router-link
							>
						</li>

						<li class="nav-item">
							<router-link
								:to="{ name: 'about' }"
								class="nav-link pt-md-3"
								:class="{ selected: $route.name == 'about' }"
								>{{ $t("navbar.about-us") }}</router-link
							>
						</li>

						<li class="nav-item">
							<router-link
								:to="{ name: 'categories' }"
								class="nav-link pt-md-3"
								:class="{
									selected:
										$route.name == 'categories' ||
										$route.name == 'view-category',
								}"
								>{{ $t("navbar.categories") }}</router-link
							>
						</li>

						<li class="nav-item">
							<router-link
								:to="{ name: 'albums' }"
								class="nav-link pt-md-3"
								:class="{
									selected:
										$route.name == 'albums' || $route.name == 'view-album',
								}"
								>{{ $t("navbar.gallery") }}</router-link
							>
						</li>

						<li class="nav-item">
							<div
								@click="showContactUs = true"
								class="nav-link pt-md-3"
								style="color: #090f2f"
							>
								{{ $t("navbar.contact") }}
							</div>
						</li>

						<li class="nav-item">
							<button class="lang-btn pt-md-3" @click="changeI18nLanguage()">
								{{ $t("lang") }}
							</button>
						</li>
						<li style="position: relative" class="nav-item">
							<search-box></search-box>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<div
			class="contact-us center-item"
			:class="{ active: showContactUs }"
			@click.self="showContactUs = false"
		>
			<div class="background">
				<contact-us></contact-us>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import SearchBox from "./SearchBox";
import ContactUs from "../pages/contact-us/Index.vue";

// change details in details.js file
import details from "../details";

import { changeI18nLanguage } from "../lang/i18n";

export default {
	name: "navbar",

	data() {
		return {
			searchBox: false,
			details,
			collpased: true,
			showSearchBox: false,
			showContactUs: false,
		};
	},

	components: {
		SearchBox,
		ContactUs,
	},

	watch: {
		showContactUs() {
			document.querySelector("body").classList.toggle("active");
		},
	},

	methods: {
		changeI18nLanguage,
	},

	computed: {
		...mapState({
			allCategories: (state) => state.categories.categories,
		}),
	},
};
</script>

<style lang="scss" scoped>
@import "../scss/shared-vue.scss";

.navbar-brand-image {
	width: 18rem;
	@media (max-width: $md-size) {
		width: 12rem;
	}
}
.navbar-nav {
	width: 100%;
	justify-content: space-between;
	padding-inline-start: 60px;
}
.nav-top {
	position: absolute;
	top: 0;
	width: 100%;
	transition: all 0.45s;
	z-index: 10;
}

.nav-link {
	border-bottom: 3px solid transparent;
	width: max-content;
}
@media (max-width: 992px) {
	.navbar-nav {
		padding: 0;
	}
	.nav-item {
		padding: 7px 0;
	}
	.nav-item * {
		display: block;
		margin: auto;
	}
}
.nav-link:hover {
	border-bottom: 3px solid $color-secondary;
}
.nav-link.selected {
	border-bottom: 3px solid $color-secondary;
}
// TODO: delete definition of class nav-home it is the same of nav-top

.fa-chevron-right {
	font-size: 0.8rem;
}

a:not(.button) {
	&:link,
	&:visited {
		color: $color-secondary;
	}
}

.lang-btn {
	background-color: transparent;
	border: none;
}

.contact-us {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 100vh;
	background: #000a;
	z-index: 100;
	display: none;
	&.active {
		display: flex;
	}
	.background {
		max-width: 780px;
		background: #f2f2f2;
		padding: 50px;
		border-radius: 10px;
		@media (max-width: 992px) {
			padding: 30px 20px;
			margin: 0 30px;
		}
	}
}
</style>
<style>
body.active {
	overflow: hidden;
}
</style>