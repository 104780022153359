var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"position-relative",attrs:{"id":"header"}},[_c('svg',{staticClass:"svg-of-header",attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 1920 143.7","xml:space":"preserve"}},[_c('path',{staticClass:"st0",attrs:{"d":"M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5","fill":"#FFFFFF"}})])]),_c('main',[_c('section',{staticClass:"pb-3"},[_c('div',{staticClass:"container text-center"},[_c('breadcrumbs',{staticClass:"mb-4 text-start",attrs:{"links":[
						{
							name: _vm.$t('navbar.home'),
							path: '/',
						},
						{
							name: _vm.$t('navbar.categories'),
							path: '/categories',
							active: true,
						} ]}}),_c('h2',{staticClass:"heading-2 text-uppercase mb-4"},[_vm._v(" "+_vm._s(_vm.$t("page.categories"))+" ")]),(_vm.loading)?_c('loader',{staticClass:"\n\t\t\t\t\t\td-flex\n\t\t\t\t\t\tjustify-content-center\n\t\t\t\t\t\talign-content-center\n\t\t\t\t\t\tflex-column\n\t\t\t\t\t",staticStyle:{"height":"50vh"}}):_c('div',{staticClass:"row"},_vm._l((_vm.categories),function(categorie){return _c('div',{key:categorie.id,staticClass:"col-lg-3 col-sm-6 mb-4"},[_c('category-card',{staticClass:"mx-3",attrs:{"id":categorie.id,"title":categorie.name,"image":categorie.image}})],1)}),0)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }