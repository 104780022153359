import * as types from '../mutation-types';

const state = () => ({
    products: [],
    articles: [],
    albums: [],
});

const actions = {
    fetchAll({ commit }, query) {
        return axios.get('/products/search', { params: { text: query } }).then(response => {
            commit(types.STORE_SEARCH, response.data);
        });
    },
};

const mutations = {
    [types.STORE_SEARCH](state, data) {
        state.articles = data.news;
        state.products = data.product;
        state.albums = data.albums;
    },
};

const getters = {
    getById: state => id => state.images.find(t => t.id === id),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};