export function getError(error) {
	console.log(error);
	if (error.response) {
 if (!error.response.data || !error.response.data.Message) {
			return 'Unexpected error';
		} else {
			if (error.response.status === 429 && error.response.headers['retry-after']) { return 'Try again later'; } else if (error.response.status === 401) { return 'Invalid credentials.'; } else return error.response.data.Message;
		} 
} else {
		return 'Network error';
	}
}

export function getDetailedError(error) {
	if (error.response) {
		if (error.response.status >= 400 && error.response.status < 500) { return 'Error ocurred while saving. Please check the information & try again.'; } else if (error.response.status >= 500) { return 'A server-side error ocurred.'; }
	} else {
		return 'Network error.';
	}
}

export function nonDeletedExcept(value, id) {
	return value.id === id || !value.is_deleted;
}

export function nonDeletedExceptMany(value, ids) {
	return ids.includes(value.id) || !value.is_deleted;
}

export function nonDeleted(value) {
	return !value.is_deleted;
}

export function unionState(oldArray, newArray, oldField = 'id', newField = 'id') {
	const newIds = newArray.map(newItem => dig(newItem, newField));
	const oldIds = oldArray.map(oldItem => dig(oldItem, oldField));
	const result = [];

	for (const oldItem of oldArray) {
		const index = newIds.indexOf(dig(oldItem, oldField));

		// If old item does not exist in new payload, just add it to result
		if (index === -1) {
			result.push(oldItem);
		}
		
		// Otherwise, use new version of the item
		else {
			result.push(newArray.find(item => dig(item, newField) === dig(oldItem, oldField)));
		}
	}
	
	// Now for new items that totally do not exist in the old array, append them to the end 
	return [
		...result,
		...newArray.filter(newItem => oldIds.indexOf(dig(newItem, newField)) === -1)
	];
}

export function dig(object, property) {
	const props = property.split('.');
	let value = object;

	for (const prop of props) { value = value[prop]; }

	return value;
}

export function filter(item, queryText, itemText) {
	if (item.header) return false;

	const hasValue = val => val != null ? val : '';

	const text = hasValue(itemText);
	const query = hasValue(queryText);

	return text.toString()
		.toLowerCase()
		.indexOf(query.toString().toLowerCase()) > -1;
}