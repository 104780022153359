<template>
  <router-link :to="{ name:'view-album', params:{id: id}}">
    <div class="gallery-card" :style="'background-image: url('+ image +')'">
      <div class="gallery-card-container">
        <div class="gallery-card-details">
          <h2 class="gallery-card-title">{{title}}</h2>
          <p class="gallery-card-text">
            <slot></slot>
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["id", "title", "image"]
};
</script>

<style lang="scss">
@import "../scss/shared-vue.scss";

// Next sass variables all what you need to change main css properties
$gallery-card-height: 15rem;
$gallery-card-padding: 1rem;

$transition-duration: 0.5s;

.gallery-card {
    height: $gallery-card-height;

    display: flex;
    align-items: flex-end;

    background-position: center;
    background-size: cover;
    overflow: hidden;

    border-radius: 1.2rem;

    transition: all $transition-duration;

    &-container {
        height: 65px;
        width: 100%;

        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
        color: $color-light;
        border-radius: 1.2rem;
        background-color: rgba($color-primary, 0.2);

        transition: all $transition-duration;
    }

    &-details {
        position: absolute;
        top: 0;

        padding: $gallery-card-padding calc(#{$gallery-card-padding} * 1.5);

        transition: all $transition-duration;
    }

    &-title {
        margin-bottom: 0;
        text-transform: capitalize;
        font-weight: 500;
    }

    &-text {
        margin-bottom: 0;
        opacity: 0;

        transition: all $transition-duration;
        transition-delay: 0.2s;
    }

    &:hover {
        transform: translateY(-12px);
        box-shadow: 0 0.75rem 2rem rgba($color-dark, 0.3);
    }

    &:hover &-container {
        height: 100%;
        background-color: rgba($color-primary, 0.6);
    }

    &:hover &-details {
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover &-text {
        opacity: 1;
    }

    @include touch-remove-hov{
        transform: translateY(0px);
        
        &-container {
            height: 100%;
            background-color: rgba($color-primary, 0.6);
        }
        &-details {
            top: 50%;
            transform: translateY(-50%);
        }
        &-text {
            opacity: 1;
        }
    }
}
</style>