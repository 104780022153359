<template>
	<div id="app">
		<loader
			v-if="loading"
			class="d-flex flex-column justify-content-center align-content-center"
			style="height: 100vh"
		/>
		<template v-else>
			<navbar></navbar>
			<router-view></router-view>
			<b-footer></b-footer>
		</template>
	</div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import Loader from "./components/Loader.vue";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

export default {
	name: "App",

	data() {
		return {
			loading: false,
		};
	},

	components: {
		Navbar,
		"b-footer": Footer,
		Loader,
	},
	watch: {
		"$i18n.locale"() {
			window.axios.defaults.headers.common["accept-language"] =
				this.$i18n.locale;
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 1500);
		},
	},
};
</script>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./scss/main.scss";
@font-face {
	font-family: Poppins;
	src: url("../public/Fonts/Tajawal/Tajawal-Regular.ttf");
}
@font-face {
	font-family: PoppinsBold;
	src: url("../public/Fonts/Tajawal/Tajawal-Bold.ttf");
}
@font-face {
	font-family: PoppinsBoldSemi;
	src: url("../public/Fonts/Tajawal/Tajawal-Medium.ttf");
}
a {
}
.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: white;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 3px;
}

.pace .pace-progress-inner {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #fff, 0 0 5px #e4941d;
	opacity: 1;
	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-moz-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	-o-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
	display: block;
	position: fixed;
	z-index: 2000;
	top: 15px;
	right: 15px;
	width: 14px;
	height: 14px;
	border: solid 2px transparent;
	border-top-color: #fff;
	border-left-color: #fff;
	border-radius: 10px;
	-webkit-animation: pace-spinner 400ms linear infinite;
	-moz-animation: pace-spinner 400ms linear infinite;
	-ms-animation: pace-spinner 400ms linear infinite;
	-o-animation: pace-spinner 400ms linear infinite;
	animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-moz-keyframes pace-spinner {
	0% {
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-o-keyframes pace-spinner {
	0% {
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-ms-keyframes pace-spinner {
	0% {
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes pace-spinner {
	0% {
		transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/* ============================= */
.svg-of-header {
	position: absolute;
	bottom: 0;
}

.fs-7 {
	font-size: 14px;
}
</style>