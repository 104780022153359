import * as types from '../mutation-types';
import { unionState } from '../../helpers';

const state = () => ({
	products: []
});

const actions = {
	fetchAll({ commit }) {
		return axios.get('/products').then(response => {
			commit(types.STORE_PRODUCTS, response.data);
		});
	},

	fetchFromCategory({ commit }, { id }) {
		return axios.get(`/categories/${id}`).then(response => {
			commit(types.STORE_PRODUCTS, response.data.products);
		});
	},

	fetch({ commit }, { id }) {
		return axios.get(`/products/${id}`).then(response => {
			commit(types.STORE_ONE_PRODUCT, response.data);
		});
	},
};

const mutations = {
	[types.STORE_PRODUCTS](state, products) {
		state.products = products;
	},

	[types.STORE_ONE_PRODUCT](state, product) {
		state.products = unionState(state.products, [product]);
	}
};

const getters = {
	getById: state => id => state.products.find(t => t.id === id),
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};