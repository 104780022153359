var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"position-relative",attrs:{"id":"header"}},[_c('svg',{staticClass:"svg-of-header",attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 1920 143.7","xml:space":"preserve"}},[_c('path',{staticClass:"st0",attrs:{"d":"M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5","fill":"#FFFFFF"}})])]),_c('main',[_c('div',{staticClass:"container my-5"},[_c('breadcrumbs',{staticClass:"mb-4",attrs:{"links":[
					{
						name: _vm.$t('navbar.home'),
						path: '/',
					},
					{ name: _vm.$t('navbar.categories'), path: '/categories' },
					{
						name: _vm.categorie.name,
						path: '/categories' + _vm.$route.params.id,
						active: true,
					} ]}}),_c('h1',{staticClass:"heading-1 text-center mb-5 text-uppercase"},[_vm._v(" "+_vm._s(_vm.categorie.name)+" ")]),(_vm.loading)?_c('loader',{staticClass:"d-flex justify-content-center align-content-center flex-column",staticStyle:{"height":"50vh"}}):_c('div',{staticClass:"row"},[_vm._l((_vm.categorie.products),function(product){return _c('div',{key:product.id,staticClass:"col-lg-3 col-sm-6 col-12 mb-3"},[_c('product-card',{attrs:{"data":product,"links":{
							name: 'view-product',
							params: { id: product.id },
							query: { cate: _vm.categorie.id },
						}}})],1)}),(!_vm.categorie.products.length)?_c('div',{staticClass:"fs-2 text-center text-uppercase text-secondary"},[_vm._v(" "+_vm._s(_vm.$t("page.no-products"))+" ")]):_vm._e()],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }