import * as types from '../mutation-types';

const state = () => ({
    values: [],
});

const actions = {
    fetchAll({ commit }) {
        return axios.get('/values').then(response => {
            commit(types.STORE_VALUES, response.data);
        });
    },
};

const mutations = {
    [types.STORE_VALUES](state, values) {
        state.values = values;
    },
};

const getters = {
    getById: state => id => state.values.find(t => t.id === id),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};