export default {
    socialMedia:{
        facebook:'#',
        linkedin:'#',
        instagram:'#',
        twitter:'#',
    },
    companyDetails:{
        address: 'Des Moines, 2128 Hazelwood Avenue',
        telephone: '515-285-2326',
        email: '12dso0kl945g@temporary-mail.net'
    }
}