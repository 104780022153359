import Vue from 'vue';
import Vuex from 'vuex';
import albums from './modules/albums';
import categories from './modules/categories';
import news from './modules/news';
import products from './modules/products';
import sliderImages from './modules/slider-images';
import contactUs from './modules/contact-us';
import aboutUs from './modules/about-us';
import values from './modules/values';
import search from './modules/search';

const debugging = process.env.NODE_ENV !== 'production';

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		albums,
		categories,
		news,
		products,
		sliderImages,
		contactUs,
		aboutUs,
		values,
		search
	},

	strict: debugging
});