import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

export default new VueRouter({
	routes,
	mode: 'history',
	linkExactActiveClass: 'active',

	scrollBehavior(to, from) {
		if (to.path === from.path && to.hash.length === 0) { return; }
		if (to.hash) {
			document.getElementById(to.hash.substr(1)).scrollIntoView();
			return;
		}

		document.documentElement.style.scrollBehavior = 'unset';
		window.scrollTo({ top: 0 });
		document.documentElement.style.scrollBehavior = 'smooth';
	},
});