<template>
    <router-link
      tag="div" :to="{ name:'view-news-entry', params:{id:id} }"
      class="news-card"
      :style="'background-image: url('+ image +')'"
    >
        <div class="news-card-content">
            <p class="news-card-text"><slot></slot></p>
            <div class="news-card-date">
                <i class="far fa-calendar-alt ms-2"></i>
                {{date}}
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props:[
        'image',
        'date',
        'id'
    ]

}
</script>

<style lang="scss">
@import '../scss/shared-vue.scss' ;

// Next sass variables all what you need to change main css properties
$card-width: 20rem;
$card-height: 15rem;
$card-border-radius: 1.2rem;
$card-transition-duration: .3s;
$card-transition-duration-date: .35s;
$card-text-size: 1rem;

$text-padding-horizontal: 1rem;
$text-padding-bottom: .8rem;
$text-margin-buttom: 0;

$color-dark: #333;
$color-light: #fff;
$text-light-color: #fff;

.news-card{
    display: inline-block;
    width: $card-width;
    height: $card-height;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    cursor: pointer;

    border-radius: $card-border-radius;

    transition: all $card-transition-duration;

    &:hover{
        transform: translateY(-12px);
        box-shadow: 0 .75rem 2rem rgba($color-dark, .3);
    }
    
    &-content{
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        
        width: 100%;
        height: 100%;
        
        border-radius: $card-border-radius;

        transition: all $card-transition-duration;

        background-color: rgba($color-dark, .3);
    }

    &-text{
        position: absolute;
        color: $text-light-color;
        
        margin-bottom: $text-margin-buttom;
        font-size: $card-text-size;
        padding: 0 $text-padding-horizontal;
        padding-bottom: $text-padding-bottom;
        
        text-transform: capitalize;
        text-align: start;

        transition: all $card-transition-duration;
    }

    &-date{
        color: $color-light;
        padding: 0 calc(#{$text-padding-horizontal} - 6px);
        // The next three lines for hover effects for date
        opacity: 0;
        transform: translateY(0px);
        transition: all $card-transition-duration-date;
    }

    &:hover &-content{
        background-color: rgba($color-dark, .5);
    }

    &:hover &-text{
        padding-bottom: 0;
        transform: translateY(-40px);
    }

    &:hover &-date{
        opacity: 1;
        transform: translateY(-15px);
    }

    // remove hover effect for touch devices
    @include touch-remove-hov {
        &:hover{
            transform: none;
            box-shadow: none;
        }
        &-content{
            background-color: rgba($color-dark, .5);
        }

        &-text{
            padding-bottom: 0;
            transform: translateY(-40px);
        }

        &-date{
            opacity: 1;
            transform: translateY(-15px);
        }
    }
}
</style>