<template>
	<footer>
		<!-- svg before footer content -->
		<svg
			class="svg-before-footer-content"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1920 143.7"
			xml:space="preserve"
		>
			<path
				d="M-0.5,1.2c143,46,493.2,144.5,975,142.5c464.4-2,801.7-96.4,945-142.5"
				stroke-width="1"
				stroke="gray"
				fill="white"
			/>
		</svg>

		<!-- footer content -->
		<div class="container">
			<div id="middle-footer" class="row justify-content-between">
				<!-- logo -->
				<div
					class="
						col-lg-4 col-sm-12
						mx-lg-0 mx-auto
						mb-lg-0 mb-5
						text-lg-start text-center
						d-flex
						logo
					"
				>
					<router-link :to="{ name: 'home' }" class="logo-img me-4">
						<img src="/Images/Footer-logo.png" alt="" />
					</router-link>
					<div class="text-start">
						<h3 class="text-uppercase">spikelube</h3>
						{{ $t("footer.lorem") }}
						<div class="d-flex footer-social mt-2">
							<a
								:href="contact.Value"
								target="_blank"
								v-for="contact in contactsSocial"
								:key="contact.id"
							>
								<i :class="findIcon(contact.Key)" class="footer-social-item">
								</i>
							</a>
						</div>
					</div>
				</div>

				<!-- helpfull links -->
				<div
					class="
						col-xl-2 col-lg-3 col-sm-6
						mb-lg-0 mb-5
						text-lg-start text-center
					"
				>
					<div class="links-title">{{ $t("footer.links") }}</div>
					<div class="d-flex justify-content-lg-start justify-content-center">
						<ul>
							<li>
								<router-link class="footer-link" :to="{ name: 'home' }">{{
									$t("navbar.home")
								}}</router-link>
							</li>
							<li>
								<router-link class="footer-link" :to="{ name: 'categories' }">{{
									$t("navbar.categories")
								}}</router-link>
							</li>
						</ul>
						<ul style="margin-inline-start: 2rem">
							<li>
								<router-link class="footer-link" :to="{ name: 'about' }">{{
									$t("footer.about")
								}}</router-link>
							</li>
							<li>
								<router-link class="footer-link" :to="{ name: 'albums' }">{{
									$t("navbar.gallery")
								}}</router-link>
							</li>
						</ul>
					</div>
				</div>

				<!-- company address -->
				<div class="col-xl-3 col-lg-3 col-sm-6 text-lg-start text-center">
					<div class="links-title">{{ $t("footer.contact-info") }}</div>
					<ul>
						<li
							v-for="contact in contactsDirect"
							:key="contact.id"
							class="footer-link text-lowercase contact-item"
						>
							<i :class="findIcon(contact.Key)"></i>
							<p dir="ltr">
								{{ contact.Value }}
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- bottom footer -->
		<div id="bottom-footer" dir="ltr">
			<div class="container d-flex justify-content-between align-items-center">
				<span>Copyright©2021.All Rights Reseved</span>
				<span
					>Powerd by
					<a target="_blank" href="https://www.beetronix.com">
						<img
							class="beetronix-logo"
							src="../assets/logo.png"
							alt="beetronix logo"
						/>
					</a>
				</span>
			</div>
		</div>
	</footer>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "b-footer",
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			products: (state) => state.products.products,
			categories: (state) => state.categories.categories,
			contacts: (state) => state.contactUs.contacts,
		}),
		contactsDirect() {
			return this.contacts.filter(
				(e) => e.Key === "phone" || e.Key === "email"
			);
		},
		contactsSocial() {
			return this.contacts.filter(
				(e) => e.Key !== "phone" && e.Key !== "email"
			);
		},
	},
	methods: {
		fetchContacts() {
			this.$store
				.dispatch("contactUs/fetchAll")
				.then()
				.finally(() => {
					this.loading = false;
				});
		},
		findIcon(key) {
			switch (key) {
				case "phone":
					return "fa fa-phone";
				case "email":
					return "fa fa-envelope";
				case "facebook":
					return "fab fa-facebook-f";
				case "instagram":
					return "fab fa-instagram";
				case "twitter":
					return "fab fa-twitter";
				default:
					return "fa fa-phone";
			}
		},
	},
	created() {
		this.fetchContacts();
	},
};
</script>
<style lang="scss" scoped>
@import "../scss/shared-vue.scss";

$footer-image: "../../public/img/footer-bg.png";
$footer-image-small: "../../public/img/footer-bg-small.png";

footer {
	position: relative;
	background: $color-secondary;
	color: $color-light-1;
	// the next top padding for give place for svg-before-footer
	padding-top: 180px;
}

.footer-social {
	&-item {
		border: 1px solid white;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		* {
			height: 80%;
		}
	}
	&-item {
		margin-right: 5px;
	}
}
.svg-before-footer-content {
	position: absolute;
	top: -1px;
}

#middle-footer {
	margin-bottom: 40px;
}

.logo {
	max-width: 400px;
	&-img {
		display: block;
		height: 10rem;
		img {
			height: 100%;
		}
	}
}

ul {
	list-style: none;
	padding: 0;
	margin-bottom: 0;
}

.links-title {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 1.1rem;
	margin-bottom: 1rem;
}

.footer-link {
	color: $text-secondary-color;
	margin-bottom: 0.5rem;
	text-transform: capitalize;
	font-weight: 500;
	display: block;
	width: max-content;
	&:hover {
		color: $color-primary;
	}
	i {
		margin-inline-end: 5px;
	}
	p {
		display: inline-block;
	}
}

#bottom-footer {
	background-color: $color-light-2;
	padding: 15px 0;
	font-size: 0.7rem;
	font-weight: 500;
	border-top: 2px solid #626678;
	.beetronix-logo {
		width: 30px;
		filter: brightness(100);
	}
}
@media (max-width: 992px) {
	.contact-item {
		margin: auto;
	}
}
</style>