var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-top",class:{ 'back-color-primary': !_vm.collpased },attrs:{"id":"nav"}},[_c('nav',{staticClass:"navbar navbar-expand-lg bg-transparent"},[_c('div',{staticClass:"container pt-2"},[_c('router-link',{staticClass:"navbar-brand fs-3",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"navbar-brand-image",attrs:{"src":"/Logo/Logo.png","alt":""}})]),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbar","aria-controls":"navbar","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":function($event){_vm.collpased = !_vm.collpased}}},[_vm._m(0)]),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbar"}},[_c('ul',{staticClass:"navbar-nav mb-2 mb-lg-0 align-items-between"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link pt-md-3",class:{ selected: _vm.$route.name == 'home' },attrs:{"to":{ name: 'home' }}},[_vm._v(_vm._s(_vm.$t("navbar.home")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link pt-md-3",class:{ selected: _vm.$route.name == 'about' },attrs:{"to":{ name: 'about' }}},[_vm._v(_vm._s(_vm.$t("navbar.about-us")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link pt-md-3",class:{
									selected:
										_vm.$route.name == 'categories' ||
										_vm.$route.name == 'view-category',
								},attrs:{"to":{ name: 'categories' }}},[_vm._v(_vm._s(_vm.$t("navbar.categories")))])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link pt-md-3",class:{
									selected:
										_vm.$route.name == 'albums' || _vm.$route.name == 'view-album',
								},attrs:{"to":{ name: 'albums' }}},[_vm._v(_vm._s(_vm.$t("navbar.gallery")))])],1),_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"nav-link pt-md-3",staticStyle:{"color":"#090f2f"},on:{"click":function($event){_vm.showContactUs = true}}},[_vm._v(" "+_vm._s(_vm.$t("navbar.contact"))+" ")])]),_c('li',{staticClass:"nav-item"},[_c('button',{staticClass:"lang-btn pt-md-3",on:{"click":function($event){return _vm.changeI18nLanguage()}}},[_vm._v(" "+_vm._s(_vm.$t("lang"))+" ")])]),_c('li',{staticClass:"nav-item",staticStyle:{"position":"relative"}},[_c('search-box')],1)])])],1)]),_c('div',{staticClass:"contact-us center-item",class:{ active: _vm.showContactUs },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.showContactUs = false}}},[_c('div',{staticClass:"background"},[_c('contact-us')],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"navbar-toggler-icon"},[_c('i',{staticClass:"fa fa-bars"})])}]

export { render, staticRenderFns }