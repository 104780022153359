import * as types from "../mutation-types"

const state = () => ({
	contacts: []
});

const actions = {
	// eslint-disable-next-line no-unused-vars
	sendFeedback({ commit }, { name, email, type, body }) {
		return axios.post('/feedback', {
			name, email, type, body
		}).then(response => {
			return response.data;
		});
	},
	fetchAll({ commit }) {
		return axios.get('/contact-us').then(response => {
			commit(types.STORE_CONTACT_US, response.data);
		});
	},
};

const mutations = {
	[types.STORE_CONTACT_US](state, contacts) {
		state.contacts = contacts
	}
};

const getters = {

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};