<template>
	<section id="contact-us" class="mb-5">
		<h2 class="heading-2 mb-4" style="line-height: 2rem">
			{{ $t("about-us.contact-us") }}
		</h2>

		<h3 class="heading-3 mb-4 fs-4">{{ $t("about-us.send-us") }}</h3>

		<!-- form -->
		<form class="text-start" @submit.prevent="submit" novalidate ref="form">
			<div class="row mb-3">
				<div class="col-sm">
					<label for="name" class="mb-name">{{ $t("about-us.name") }}</label>
					<input
						for="name"
						type="text"
						class="form-control"
						v-model="name"
						:disabled="loading"
						:class="{ 'not-valid': !validate }"
						required
					/>
				</div>
				<div class="col-sm">
					<label for="email"
						>{{ $t("about-us.email") }}
						<span class="small-label">{{
							$t("about-us.email-holder")
						}}</span></label
					>
					<input
						for="email"
						type="email"
						class="form-control"
						v-model="email"
						:disabled="loading"
						:class="{ 'not-valid': !validate }"
						required
					/>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-sm">
					<label for="description">{{ $t("about-us.description") }}</label>
					<textarea
						id="description"
						type="text"
						class="form-control"
						v-model="description"
						:disabled="loading"
						:class="{ 'not-valid': !validate }"
					></textarea>
				</div>
			</div>

			<button class="button button--outline d-block px-5" type="submit">
				{{ $t("about-us.send") }}
			</button>
		</form>
	</section>
</template>

<script>
import store from "../../store";
// import contactCard from "../../components/ContactCard";

export default {
	data: () => ({
		name: null,
		email: null,
		type: null,
		body: null,
		description: null,

		loading: false,
		validate: true,
	}),

	components: {
		// contactCard,
	},

	methods: {
		async submit() {
			if (this.$refs.form.checkValidity()) {
				this.loading = true;
				this.validate = true;
				await store
					.dispatch("contactUs/sendFeedback", {
						name: this.name,
						email: this.email,
						type: this.type,
						body: this.body,
					})
					.then(() => {
						alert("Your feedback was sent !");
						this.name = null;
						this.email = null;
						this.type = null;
						this.body = null;
					})
					.catch((error) => {
						alert(error + "\nYour feedback was not sent , please try again.");
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				console.log(123);
				this.validate = false;
			}
		},
	},
};
</script>

<style lang="scss">
@import "../../scss/shared-vue.scss";
// Next sass variables all what you need to change main css properties
$shape-size: 350px;

.contact-us {
	&-shape {
		width: $shape-size;
		height: $shape-size;
		border-radius: 50%;
		overflow: hidden;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
	&-image {
		height: 100%;
	}
}
.not-valid {
	border: 1px solid red !important;
}
.small-label {
	font-size: 0.6em;
	line-height: 0.8rem;
	display: block;
}
.mb-name {
	margin-bottom: 1.05rem;
}
</style>
