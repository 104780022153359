<template>
	<div class="category-card" :style="'background-image: url(' + image + ')'">
		<div class="category-card-content">
			<h2 class="category-card-title">{{ title }}</h2>
			<router-link
				:to="{
					name: 'view-category',
					params: { id: id },
				}"
				class="category-card-button"
			>
				{{ $t("page.view") }}
				<i class="fas fa-arrow-right ms-2"></i>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	props: ["id", "image", "title"],
};
</script>

<style lang="scss">
@import "../scss/shared-vue.scss";

// Next sass variables all what you need to change main css properties
$card-width: 15rem;
$card-height: 15rem;
$card-border-radius: 1.2rem;
$card-transition-duration: 0.3s;
$card-transition-duration-button: 0.45s;
$card-title-size: 1.2rem;

$title-margin-bottom: 0;
$title-font-weight: 400;

$color-dark: #333;
$color-light: #fff;
$text-light-color: #fff;

.category-card {
	display: inline-block;
	width: $card-width;
	height: $card-height;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	border-radius: $card-border-radius;

	transition: all $card-transition-duration;

	&:hover {
		transform: translateY(-12px);
		box-shadow: 0 0.75rem 2rem rgba($color-dark, 0.3);
	}

	&-content {
		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		width: 100%;
		height: 100%;

		border-radius: $card-border-radius;

		transition: all $card-transition-duration;

		background-color: rgba($color-dark, 0.3);
	}

	&-title {
		position: absolute;
		color: $text-light-color;

		margin-bottom: $title-margin-bottom;
		font-weight: $title-font-weight;
		font-size: $card-title-size;
		letter-spacing: 1px;
		text-transform: capitalize;

		transition: all $card-transition-duration;
	}

	&-button {
		display: inline-flex;
		align-items: center;
		border-radius: 20px;
		font-size: 1rem;
		font-weight: 300;
		padding: 0.25rem 1.35rem;
		color: $text-light-color;
		background-color: transparent;
		border: 1px solid $color-light;

		// The next three lines for hover effects for button
		opacity: 0;
		transform: translateY(50px);
		transition: all $card-transition-duration-button;

		&:hover {
			color: $color-dark;
			background-color: $color-light;
		}
	}

	&:hover &-content {
		background-color: rgba($color-dark, 0.5);
	}

	&:hover &-title {
		transform: translateY(-20px);
	}

	&:hover &-button {
		opacity: 1;
		transform: translateY(20px);

		&:hover {
			transform: translateY(20px) scale(1.1);
		}

		&:active {
			transform: translateY(20px) scale(1);
		}
	}

	// remove hover effect for touch devices
	@include touch-remove-hov {
		&:hover {
			transform: none;
			box-shadow: none;
		}

		&-content {
			background-color: rgba($color-dark, 0.5);
		}

		&-title {
			transform: translateY(-20px);
		}

		&-button {
			opacity: 1;
			transform: translateY(20px);
		}
	}

	/*
	    animation-play-state: running ;

	    animation: floatup $card-transition-duration ease-in-out .2s forwards alternate;
	    animation-play-state: paused;

	    @keyframes floatup {
	        from{
	            opacity: 0;
	            transform: translateY(50px);
	        }
	        to{
	            opacity: 1;
	            transform: translateY(20px);
	        }
	    }
	*/
}
</style>