<template>
	<div>
		<header class="position-relative" id="header">
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>
		<loader
			v-if="loading"
			style="height: 50vh"
			class="d-flex justify-content-center align-content-center flex-column"
		></loader>
		<main v-else>
			<section class="container text-center" style="margin-bottom: 100px">
				<breadcrumbs
					class="mb-4 text-start"
					:links="[
						{
							name: $t('navbar.home'),
							path: '/',
						},
						{ name: $t('navbar.about-us'), path: '/about-us', active: true },
					]"
				></breadcrumbs>
				<div class="mb-5 about-us" v-for="about in aboutUs" :key="about.id">
					<h3 class="heading-3 text-capitalize">
						{{ $t(`about-us.${about.Key}`) }}
					</h3>
					<p v-html="about.Value"></p>
				</div>
				<div class="privileges row justify-content-center">
					<div
						class="col-md-2 col-sm-4 col-6"
						v-for="value in values.slice(0, 5)"
						:key="value.id"
					>
						<privileges-card :data="value"></privileges-card>
					</div>
				</div>
			</section>

			<div class="container mb-5">
				<div class="row justify-content-evenly">
					<content-us class="col-lg-7"></content-us>
					<div class="col-lg-4 contact-links">
						<ul>
							<h2 class="heading-2 contact-title fs-4">
								{{ $t("about-us.contact-info") }}
							</h2>
							<li
								class="contact-links-item"
								v-for="contact in contactsDirect"
								:key="contact.id"
							>
								<i
									:class="findIcon(contact.Key)"
									class="contact-links-item-icon"
								></i>
								<p class="text-lowercase contact-links-item-text" dir="ltr">
									{{ contact.Value }}
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import contentUs from "../contact-us/Index";
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import Loader from "../../components/Loader.vue";
import { mapState } from "vuex";
import privilegesCard from "../../components/privilegesCard.vue";

export default {
	components: {
		contentUs,
		Breadcrumbs,
		Loader,
		privilegesCard,
	},
	data() {
		return {
			loading: true,
		};
	},
	methods: {
		fetchAbout() {
			this.$store
				.dispatch("aboutUs/fetchAll")
				.then()
				.finally(() => {
					this.loading = false;
				});
		},
		fetchValues() {
			this.$store
				.dispatch("values/fetchAll")
				.then()
				.finally(() => {});
		},
		findIcon(key) {
			switch (key) {
				case "phone":
					return "fa fa-phone";
				case "email":
					return "fa fa-envelope";
				case "facebook":
					return "fab fa-facebook-f";
				case "instagram":
					return "fab fa-instagram";
				case "twitter":
					return "fab fa-twitter";
				default:
					return "fa fa-phone";
			}
		},
	},
	computed: {
		...mapState({
			aboutUs: (state) => state.aboutUs.aboutUs,
			values: (state) => state.values.values,
			contacts: (state) => state.contactUs.contacts,
		}),
		contactsDirect() {
			return this.contacts.filter(
				(e) => e.Key === "phone" || e.Key === "email"
			);
		},
	},
	created() {
		this.fetchAbout();
		this.fetchValues();
	},
};
</script>
<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";

#header {
	background: linear-gradient(to left, #b8c2c6, #bac4c6);
	height: 220px;
	@media (min-width: $hd-size) {
		height: 300px;
	}
}
.last-section {
	margin-bottom: 5rem;
}

.contact-links {
	ul {
		list-style: none;
	}
	&-item {
		margin-bottom: 1.5rem;
		font-weight: 500;
		font-size: 1.1rem;
		&-icon {
			width: 1rem;
			margin-inline-end: 0.5rem;
		}
		&-text {
			display: inline-block;
		}
	}
	@media (max-width: $lg-size) {
		border: none;
		text-align: center;
		ul {
			padding: 0;
		}
	}
}
.contact-title {
	margin-top: 3.5rem;
	margin-bottom: 1.5rem;
}
</style>
<style >
.about-us p {
	margin-bottom: 0.3rem;
}
</style>