import * as types from '../mutation-types';
import i18n from '../../lang/i18n'

const state = () => ({
    aboutUs: [],
});

const actions = {
    fetchAll({ commit }) {
        return axios.get('/about-us', { 'accept-language': i18n.locale }).then(response => {
            commit(types.STORE_ABOUT_US, response.data);
        });
    },
};

const mutations = {
    [types.STORE_ABOUT_US](state, aboutUs) {
        state.aboutUs = aboutUs;
    },
};

const getters = {
    getById: state => id => state.categories.find(t => t.id === id),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};