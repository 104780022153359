var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"position-relative",attrs:{"id":"header"}},[_c('svg',{staticClass:"svg-of-header",attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 1920 143.7","xml:space":"preserve"}},[_c('path',{staticClass:"st0",attrs:{"d":"M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5","fill":"#FFFFFF"}})])]),(_vm.loading)?_c('loader',{staticClass:"d-flex justify-content-center align-content-center flex-column",staticStyle:{"height":"50vh"}}):_c('main',[_c('section',{staticClass:"mb-5"},[_c('div',{staticClass:"container"},[_c('breadcrumbs',{staticClass:"mb-4",attrs:{"links":[
						{
							name: _vm.$t('navbar.home'),
							path: '/',
						} ].concat( _vm.breadcrumbsLinks,
						[{
							name: _vm.product.name,
							path: '/products/' + _vm.product.id,
							active: true,
						}] )}}),_c('div',{staticClass:"product row"},[_c('div',{staticClass:"product-image col-lg-6 col-12 center-item mb-5"},[_c('div',{staticClass:"product-image-btn product-image-btn-left",on:{"click":function($event){return _vm.moveLeft()}}},[_c('i',{staticClass:"fa fa-angle-left"})]),_c('div',{staticClass:"product-image-btn product-image-btn-right",on:{"click":function($event){return _vm.moveRight()}}},[_c('i',{staticClass:"fa fa-angle-right"})]),_c('div',{ref:"imageContainer",staticClass:"product-image-container",style:({
								width: _vm.product.images.length * 100 + '%',
								left: _vm.imagePosition + '%',
							})},_vm._l((_vm.product.images),function(img){return _c('div',{key:img,staticClass:"product-image-item center-item",style:({ width: 100 / _vm.product.images.length + '%' })},[_c('img',{attrs:{"src":_vm.product.primary_image,"alt":""}})])}),0)]),_c('div',{staticClass:"product-data col-lg-6 col-12"},[_c('h2',{staticClass:"product-data-name heading-4 mb-3"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.product.Description)}}),(_vm.product.files.length)?_c('div',{staticClass:"product-data-download"},[_c('h5',{staticClass:"heading-4 text-uppercase mb-3"},[_vm._v(" "+_vm._s(_vm.$t("page.download"))+" ")]),_c('a',{attrs:{"href":_vm.product.files[0],"target":"_blank"}},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\t\td-flex\n\t\t\t\t\t\t\t\t\t\tp-3\n\t\t\t\t\t\t\t\t\t\tproduct-data-download-links\n\t\t\t\t\t\t\t\t\t\tjustify-content-between\n\t\t\t\t\t\t\t\t\t\talign-items-center\n\t\t\t\t\t\t\t\t\t"},[_c('div',[_c('a',{attrs:{"href":_vm.product.files[0],"target":"_blank"}},[_c('img',{staticClass:"me-2",attrs:{"src":"/Icons/pdf.png","alt":""}}),_vm._v(_vm._s(_vm.product.name)+" ")])]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("page.download"))),_c('img',{staticClass:"p-2",attrs:{"src":"/Icons/download.png","alt":""}})])])])]):_vm._e()])])],1)]),_c('section',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"heading-1"},[_vm._v(_vm._s(_vm.$t("page.related-products")))]),_c('div',{staticClass:"row justify-content-lg-start justify-content-center"},_vm._l((_vm.products
							.filter(function (c) { return c.id != _vm.$route.params.id; })
							.slice(0, 4)),function(product){return _c('div',{key:product.id,staticClass:"col-lg-3 col-sm-6 col-12"},[_c('product-card',{attrs:{"data":product}})],1)}),0)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }