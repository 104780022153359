export const STORE_ALBUMS = 'STORE_ALBUMS';
export const STORE_ONE_ALBUM = 'STORE_ONE_ALBUM';

export const STORE_NEWS = 'STORE_NEWS';
export const STORE_ONE_NEWS_ENTRY = 'STORE_ONE_NEWS_ENTRY';

export const STORE_CATEGORIES = 'STORE_CATEGORIES';
export const STORE_ONE_CATEGORY = 'STORE_ONE_CATEGORY';

export const STORE_PRODUCTS = 'STORE_PRODUCTS';
export const STORE_ONE_PRODUCT = 'STORE_ONE_PRODUCT';

export const STORE_SLIDER_IMAGES = 'STORE_SLIDER_IMAGES';

export const STORE_ABOUT_US = 'STORE_ABOUT_US';

export const STORE_VALUES = 'STORE_VALUES';

export const STORE_CONTACT_US = 'STORE_CONTACT_US';

export const STORE_SEARCH = 'STORE_SEARCH';