import * as types from '../mutation-types';
import { unionState } from '../../helpers';

const state = () => ({
	news: [],
});

const actions = {
    fetchAll({ commit }) {
        return axios.get('/news').then(response => {
			commit(types.STORE_NEWS, response.data);
		});
	},

	fetch({ commit }, { id }) {
        return axios.get(`/news/${id}`).then(response => {
			commit(types.STORE_ONE_NEWS_ENTRY, response.data);
		});
    },
};

const mutations = {
	[types.STORE_NEWS](state, news) {
		state.news = news;
	},

	[types.STORE_ONE_NEWS_ENTRY](state, news) {
		state.news = unionState(state.news, [news]);
	}
};

const getters = {
	getById: state => id => state.news.find(t => t.id === id),
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};