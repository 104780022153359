<template>
	<div>
		<header class="position-relative" id="header">
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>

		<main>
			<section class="pb-3">
				<div class="container text-center">
					<breadcrumbs
						class="mb-4 text-start"
						:links="[
							{
								name: $t('navbar.home'),
								path: '/',
							},
							{
								name: $t('navbar.categories'),
								path: '/categories',
								active: true,
							},
						]"
					></breadcrumbs>
					<!-- Services -->
					<h2 class="heading-2 text-uppercase mb-4">
						{{ $t("page.categories") }}
					</h2>

					<!-- services card -->
					<loader
						v-if="loading"
						style="height: 50vh"
						class="
							d-flex
							justify-content-center
							align-content-center
							flex-column
						"
					></loader>
					<div class="row" v-else>
						<div
							class="col-lg-3 col-sm-6 mb-4"
							v-for="categorie in categories"
							:key="categorie.id"
						>
							<category-card
								class="mx-3"
								:id="categorie.id"
								:title="categorie.name"
								:image="categorie.image"
							></category-card>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import { mapState } from "vuex";
import store from "../../store";
import categoryCard from "../../components/CategoryCard";
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import Loader from "../../components/Loader.vue";

export default {
	data() {
		return {
			loading: true,
		};
	},

	components: {
		categoryCard,
		Breadcrumbs,
		Loader,
	},

	computed: {
		...mapState({
			categories: (state) => state.categories.categories,
		}),
	},
	methods: {
		fetchCategories() {
			this.$store
				.dispatch("categories/fetchAll")
				.then()
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.fetchCategories();
	},
	//  TODO: remove //(comment) from next lines
	// async beforeRouteEnter(to, from, next) {
	//     await store.dispatch('categories/fetchAll');
	//     next();
	// }
};
</script>

<style lang="scss" scoped>
@import "../../scss/shared-vue";

#header {
	background: linear-gradient(to left, #b8c2c6, #bac4c6);
	height: 220px;
	@media (min-width: $hd-size) {
		height: 300px;
	}
}
.category-card {
	width: 16rem;
	height: 16rem;

	@media (min-width: $sm-size) {
		width: 13.5rem;
		height: 13.5rem;
	}

	@media (min-width: $md-size) {
		width: 15rem;
		height: 15rem;
	}

	@media (min-width: $lg-size) {
		width: 13rem;
		height: 13rem;
	}

	@media (min-width: $xl-size) {
		width: 15rem;
		height: 15rem;
	}
}
</style>