import * as types from '../mutation-types';

const state = () => ({
	images: [],
});

const actions = {
    fetchAll({ commit }) {
        return axios.get('/sliders').then(response => {
			commit(types.STORE_SLIDER_IMAGES, response.data);
		});
	},
};

const mutations = {
	[types.STORE_SLIDER_IMAGES](state, images) {
		state.images = images;
	},
};

const getters = {
	getById: state => id => state.images.find(t => t.id === id),
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};