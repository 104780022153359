<template>
    <div>
        <header class="about-us-header position-relative">
            <!-- jumbotron -->
			<div class="jumbotron">
				<div class="container">
					<div class="jumbotron__content">
						<h1 class="jumbotron__heading heading-1">
							News
						</h1>

						<p class="jumbotron__descreption">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi explicabo perspiciatis ipsa repudiandae, velit omnis nostrum quisquam atque labore sapiente fuga iusto eius beatae voluptatibus! Nesciunt soluta optio error tenetur.
						</p>
					</div>
				</div>
			</div>
            <svg class="svg-of-header" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 143.7" xml:space="preserve">
				<path class="st0" d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5" fill="#FFFFFF"/>
			</svg>
        </header>

        <main>
            <section id="news-content" class="container pt-1 text-center">
                <h2 class="heading-2 mb-4">
                    {{news[$route.params.id].title}}
                </h2>
                <img :src="news[$route.params.id].image" alt="news" class="mb-4 mw-100">
                <div class="text-start">
                    <h3>subtitle</h3>
                    <span class="text-secondary">{{news[$route.params.id].date}}</span>
                    <p>
                        {{ news[$route.params.id].contnet }}
                    </p>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '../../store';

export default {
    data(){
        return {
            news:[
                {
                    id:0,
                    title: 'title 1',
                    date: '01/02/2020',
                    contnet: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem voluptatum eos, tempore commodi aperiam nihil velit nostrum natus nobis unde inventore consequuntur illum dolor sapiente vel culpa doloremque? Nihil, quam.',
                    image: '/img/news-1.jpg'
                },
                {
                    id:1,
                    title: 'title 2',
                    date: '21/03/2020',
                    contnet: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem voluptatum eos, tempore commodi aperiam nihil velit nostrum natus nobis unde inventore consequuntur illum dolor sapiente vel culpa doloremque? Nihil, quam.',
                    image: '/img/news-2.jpg'
                },
                {
                    id:2,
                    title: 'title 3',
                    date: '07/08/2021',
                    contnet: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem voluptatum eos, tempore commodi aperiam nihil velit nostrum natus nobis unde inventore consequuntur illum dolor sapiente vel culpa doloremque? Nihil, quam.',
                    image: '/img/news-3.jpg'
                },
                {
                    id:3,
                    title: 'title 4',
                    date: '07/03/2021',
                    contnet: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem voluptatum eos, tempore commodi aperiam nihil velit nostrum natus nobis unde inventore consequuntur illum dolor sapiente vel culpa doloremque? Nihil, quam.',
                    image: '/img/news-1.jpg'
                },
            ]
        }
    },

    computed: {
        ...mapGetters({
            getNewsEntryById: 'news/getById'
        }),

        newsEntry() {
            return this.getNewsEntryById(parseInt(this.$route.params.id));
        }
    },

    mounted(){
        setTimeout(() => {
            this.$el.querySelector('#news-content').scrollIntoView();
        }, 700);
    },

    //  TODO: remove //(comment) from next lines
    // async beforeRouteEnter(to, from, next) {
    //     await store.dispatch('news/fetch', { id: to.params.id });
    //     next();
    // }
}
</script>
<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";

// Next sass variables all what you need to change main css properties
$jumbotron-side-image: '/img/news.jpg';
$jumbotron-side-image-small: '/img/news-small.jpg';

@include jumbotron-side-style($jumbotron-side-image, $jumbotron-side-image-small);
</style>