import Home from '../pages/Home';
import ProductsIndex from '../pages/products/Index';
import ViewProduct from '../pages/products/View';
import NewsIndex from '../pages/news/Index';
import ViewNewsEntry from '../pages/news/View';
import AlbumsIndex from '../pages/albums/Index';
import ViewAlbum from '../pages/albums/View';
import ContactUs from '../pages/contact-us/Index';
import About from '../pages/about/Index';
import Category from '../pages/categories/Index'
import ViewCategory from '../pages/categories/View'
import Search from '../pages/search/Search'

export default [
    {
        name: 'home',
        path: '/',
        component: Home,
    },
    {
        name: 'products',
        path: '/allproducts/:id?',
        component: ProductsIndex
    },
    {
        name: 'view-product',
        path: '/products/:id',
        component: ViewProduct
    },
    {
        name: 'news',
        path: '/news',
        component: NewsIndex
    },
    {
        name: 'view-news-entry',
        path: '/news/:id',
        component: ViewNewsEntry
    },
    {
        name: 'albums',
        path: '/albums',
        component: AlbumsIndex
    },
    {
        name: 'view-album',
        path: '/albums/:id',
        component: ViewAlbum
    },
    {
        name: 'contact-us',
        path: '/about-us',
        hash: '#contact-us',
        component: About
    },
    {
        name: 'about',
        path: '/about-us',
        component: About,
    },
    {
        name: 'categories',
        path: '/categories',
        component: Category,
    },
    {
        name: 'view-category',
        path: '/categories/:id',
        component: ViewCategory
    },
    {
        name: 'search',
        path: '/search/',
        component: Search
    }
];