import * as types from '../mutation-types';
import { unionState } from '../../helpers';

const state = () => ({
	categories: [],
});

const actions = {
	fetchAll({ commit }) {
		return axios.get('/categories').then(response => {
			commit(types.STORE_CATEGORIES, response.data);
		});
	},

	fetch({ commit }, { id }) {
		return axios.get(`/categories/${id}`).then(response => {
			commit(types.STORE_ONE_CATEGORY, response.data);
		});
	},
	fetchById({ commit }, { id }) {
		return axios.get(`/categories/${id}`).then(response => {
			return response.data
		});
	}
};

const mutations = {
	[types.STORE_CATEGORIES](state, categories) {
		state.categories = categories;
	},

	[types.STORE_ONE_CATEGORY](state, category) {
		state.categories = unionState(state.categories, [category]);
	}
};

const getters = {
	getById: state => id => state.categories.find(t => t.id === id),
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};