<template>
	<div class="myCard m-auto">
		<div class="image m-3">
			<img :src="data.image" alt="" />
		</div>
		<div class="description">{{ data.Title }}</div>
	</div>
</template>

<script>
export default {
	props: ["data"],
};
</script>

<style lang="scss">
@import "../scss/shared-vue.scss";
.myCard {
	width: min-content;
}
.image {
	border: 1px solid $color-primary;
	border-radius: 50%;
	height: 6rem;
	width: 6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		height: 60%;
	}
	@media (max-width: $lg-size) {
		height: 5rem;
		width: 5rem;
	}
}
.description {
	@media (max-width: $lg-size) {
		font-size: 0.9rem;
	}
}
</style>