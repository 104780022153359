import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios';
import router from './router';
import { API_URL } from './constants';
import PaceProgress from "pace-progress";
import i18n from './lang/i18n'

window.axios = axios.create({
	baseURL: API_URL,
});
window.axios.defaults.headers.common["accept-language"] = i18n.locale;

Vue.config.productionTip = false

new Vue({
	store,
	router,
	i18n,
	render: h => h(App),
}).$mount('#app');

PaceProgress.start();