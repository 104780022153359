<template>
	<div>
		<button @click="visible = !visible" class="search-btn pt-md-3">
			<i :class="visible ? 'fa fa-times' : 'fa fa-search'"></i>
		</button>
		<div id="search-box">
			<input
				name="search"
				type="search"
				:placeholder="$t('search')"
				@keypress.enter="search"
				v-model="value"
				:class="visible ? 'in-animation' : 'out-animation'"
				ref="input"
			/>
		</div>
	</div>
</template>

<script>
import router from "../router";

export default {
	data() {
		return {
			visible: false,
			value: "",
		};
	},

	methods: {
		search() {
			this.visible = false;
			this.$router.push({
				name: "search",
				query: { text: this.$refs.input.value },
			});
		},
	},
};
</script>

<style lang="scss">
@import "../scss/shared-vue.scss";
// Next sass variables all what you need to change main css properties
$direction: ltr;

$font-size: 1rem;

$background-color: #fff;
$text-color: #212529;
$placeholder-color: #6c757d;

$padding-x: 0.75rem;
$padding-y: 0.375rem;
.fa {
	width: 1rem;
}
.search-btn {
	background-color: transparent;
	border: none;
}
@media (min-width: $lg-size) {
	#search-box {
		width: 250px;
		position: absolute;
		bottom: 35px;
	}
}
@media (max-width: $lg-size) {
	.search-btn {
		display: none;
	}
	#search-box {
		input {
			animation-name: show !important;
		}
	}
}

#search-box {
	display: inline-block;

	@if $direction == rtl {
		direction: ltr;
	} @else {
		direction: rtl;
	}

	input {
		direction: $direction;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		display: block;
		width: 100%;
		padding: $padding-y $padding-x;
		font-size: $font-size;
		font-weight: 400;
		line-height: 1.5;
		color: $text-color;
		background-color: $background-color;
		border: unset;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0.25rem;

		&::placeholder {
			color: $placeholder-color;
		}
	}
	@media (max-width: 992px) {
		width: 100%;
		max-width: 300px;
		display: block;
		margin: auto;
	}

	.in-animation {
		animation-name: show;
		animation-direction: alternate;
		animation-fill-mode: forwards;
		animation-timing-function: ease;
		animation-duration: 0.4s;
	}

	.out-animation {
		// animation: show .3s linear 0s 1 reverse both;
		// animation-delay: 1s;
		animation-name: hide;
		animation-direction: alternate;
		animation-fill-mode: forwards;
		animation-timing-function: ease;
		animation-duration: 0.4s;
	}

	@keyframes show {
		from {
			width: 0;
			opacity: 0;
			padding: 0;
		}
		to {
			width: 100%;
			opacity: 1;
		}
	}

	@keyframes hide {
		from {
			width: 100%;
			opacity: 1;
		}
		to {
			width: 0;
			opacity: 0;
			padding: 0;
		}
	}

	/* to hide x icon from search box */
	/* clears the ‘X’ from Internet Explorer */
	input[type="search"]::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}
	input[type="search"]::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
	/* clears the ‘X’ from Chrome, FireFox and Safari*/
	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		display: none;
	}
}
</style>
<style>
[dir="ltr"] #search-box {
	right: 0;
	left: unset;
	direction: rtl;
}
[dir="rtl"] #search-box {
	right: unset;
	left: 0;
	direction: ltr;
}
</style>