<template>
	<div>
		<header class="position-relative" id="header">
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>

		<main>
			<section class="pb-5">
				<div class="container">
					<breadcrumbs
						class="mb-4 text-start"
						:links="[
							{
								name: $t('navbar.home'),
								path: '/',
							},
							{ name: $t('navbar.gallery'), path: '/albums', active: true },
						]"
					></breadcrumbs>
					<h2 class="heading-2 text-center mb-4">{{ $t("page.gallery") }}</h2>
					<loader
						v-if="loading"
						style="height: 50vh"
						class="
							d-flex
							justify-content-center
							align-content-center
							flex-column
						"
					></loader>
					<div class="row" v-else>
						<div
							class="col-lg-3 col-md-4 col-sm-6 mb-lg-0 mb-4"
							v-for="card in albums"
							:key="card.id"
						>
							<gallery-card
								:title="card.title"
								:id="card.id"
								:image="card.primary_image"
							>
							</gallery-card>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import { mapState } from "vuex";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import GalleryCard from "../../components/GalleryCard";
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import Loader from "../../components/Loader.vue";

export default {
	data() {
		return {
			loading: true,
		};
	},

	components: {
		GalleryCard,
		Breadcrumbs,
		Loader,
	},

	computed: {
		...mapState({
			albums: (state) => state.albums.albums,
		}),
	},
	methods: {
		fetchAlbums() {
			this.$store
				.dispatch("albums/fetchAll")
				.then()
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.fetchAlbums();
	},
	//  TODO: remove //(comment) from next lines
	// async beforeRouteEnter(to, from, next) {
	//     await store.dispatch('albums/fetchAll');
	//     next();
	// }
};
</script>

<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";
.images-wrapper {
	display: flex;
	justify-content: space-around;
	margin-bottom: 20px;

	> div.image {
		width: 200px;
		background-size: cover;
		background-position: center;
		height: 200px;
	}
}

#header {
	background: linear-gradient(to left, #b8c2c6, #bac4c6);
	height: 220px;
	@media (min-width: $hd-size) {
		height: 300px;
	}
}
</style>