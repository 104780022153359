<template>
	<div>
		<header class="position-relative" id="header">
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>
		<loader
			v-if="loading"
			style="height: 50vh"
			class="d-flex justify-content-center align-content-center flex-column"
		></loader>
		<main v-else>
			<section class="mb-5">
				<div class="container">
					<breadcrumbs
						class="mb-4"
						:links="[
							{
								name: $t('navbar.home'),
								path: '/',
							},
							...breadcrumbsLinks,
							{
								name: product.name,
								path: '/products/' + product.id,
								active: true,
							},
						]"
					></breadcrumbs>
					<div class="product row">
						<div class="product-image col-lg-6 col-12 center-item mb-5">
							<div
								class="product-image-btn product-image-btn-left"
								@click="moveLeft()"
							>
								<i class="fa fa-angle-left"></i>
							</div>
							<div
								class="product-image-btn product-image-btn-right"
								@click="moveRight()"
							>
								<i class="fa fa-angle-right"></i>
							</div>
							<div
								class="product-image-container"
								ref="imageContainer"
								:style="{
									width: product.images.length * 100 + '%',
									left: imagePosition + '%',
								}"
							>
								<div
									class="product-image-item center-item"
									:style="{ width: 100 / product.images.length + '%' }"
									v-for="img in product.images"
									:key="img"
								>
									<img :src="product.primary_image" alt="" />
								</div>
							</div>
						</div>
						<div class="product-data col-lg-6 col-12">
							<h2 class="product-data-name heading-4 mb-3">
								{{ product.name }}
							</h2>

							<div v-html="product.Description"></div>
							<!-- <h2 class="product-data-name heading-4 mb-3">
								spikelube sae:15w40 api:sl/cf
							</h2>
							<div class="product-data-description mb-4">
								Lorem ipsum dolor sit amet, consectetur adipisicing elit.
								Provident, qui natus nisi delectus quaerat nam facere sequi vel,
								recusandae laborum, alias officia quas voluptates. Voluptates
								laborum dolorem sapiente nemo quae.
							</div>
							<div class="product-data-info mb-5">
								<h5 class="heading-4 text-uppercase mb-3">
									additional information
								</h5>
								<div class="d-grid product-data-info-table pe-lg-5 pe-0">
									<div
										class="
											product-data-info-table-title product-data-info-table-item
										"
									>
										parameter test
									</div>
									<div
										class="
											product-data-info-table-title product-data-info-table-item
										"
									>
										unit
									</div>
									<div
										class="
											product-data-info-table-title product-data-info-table-item
										"
									>
										method
									</div>
									<div
										class="
											product-data-info-table-title product-data-info-table-item
										"
									>
										results
									</div>
									<div
										v-for="(i, index) in info"
										:key="index"
										class="product-data-info-table-item"
									>
										{{ i }}
									</div>
								</div>
							</div> -->
							<div class="product-data-download" v-if="product.files.length">
								<h5 class="heading-4 text-uppercase mb-3">
									{{ $t("page.download") }}
								</h5>
								<a :href="product.files[0]" target="_blank">
									<div
										class="
											d-flex
											p-3
											product-data-download-links
											justify-content-between
											align-items-center
										"
									>
										<div>
											<a :href="product.files[0]" target="_blank">
												<img src="/Icons/pdf.png" alt="" class="me-2" />{{
													product.name
												}}
											</a>
										</div>
										<div>
											{{ $t("page.download")
											}}<img src="/Icons/download.png" alt="" class="p-2" />
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div class="container">
					<h1 class="heading-1">{{ $t("page.related-products") }}</h1>
					<div class="row justify-content-lg-start justify-content-center">
						<div
							class="col-lg-3 col-sm-6 col-12"
							v-for="product in products
								.filter((c) => c.id != $route.params.id)
								.slice(0, 4)"
							:key="product.id"
						>
							<product-card :data="product"></product-card>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import store from "../../store";
import ProductCard from "../../components/ProductCard.vue";
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import Loader from "../../components/Loader.vue";
import i18n from "../../lang/i18n";

export default {
	components: {
		ProductCard,
		Breadcrumbs,
		Loader,
	},
	computed: {
		...mapState({
			products: (state) => state.products.products,
			categories: (state) => state.categories.categories,
		}),

		...mapGetters({
			getProductById: "products/getById",
			getCategory: "categories/getById",
		}),

		product() {
			return this.getProductById(parseInt(this.$route.params.id));
		},
		breadcrumbsLinks() {
			var links = [{ name: this.$t("navbar.products"), path: "/allproducts" }];
			if (this.$route.query.cate) {
				links = [
					{ name: this.$t("navbar.categories"), path: "/categories" },
					{
						name: this.getCategory(parseInt(this.$route.query.cate)).name,
						path: "/categories/" + this.$route.query.cate,
					},
				];
			}
			return links;
		},
	},
	data() {
		return {
			loading: true,
			imagePosition: 0,
			breadcrumbsLink: {},
			prevRoute: null,
			lungX: i18n.locale,
		};
	},

	methods: {
		fetchProduct() {
			this.$store.dispatch("products/fetchAll").finally(() => {
				this.loading = false;
			});
		},
		featchCategories() {
			Promise.all([
				this.$store.dispatch("products/fetchAll"),
				this.$store.dispatch("categories/fetchAll"),
			]).finally(() => {
				this.loading = false;
			});
		},
		moveLeft() {
			if (this.imagePosition === 0) return;
			this.imagePosition += 100;
		},
		moveRight() {
			if (this.imagePosition === -1 * (this.product.images.length - 1) * 100) {
				return;
			}
			this.imagePosition -= 100;
		},
	},
	mounted() {
		this.featchCategories();
		// if (!this.products.length) {
		// } else this.loading = false;
	},
	// beforeRouteEnter(to, from, next) {
	// 	next((vm) => {
	// 		vm.prevRoute = from;
	// 	});
	// },
};
</script>
<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";

#header {
	background: linear-gradient(to left, #b8c2c6, #bac4c6);
	height: 220px;
	@media (min-width: $hd-size) {
		height: 300px;
	}
}

.product {
	&-image {
		background: $color-light-1;
		height: 650px;
		border-radius: 1rem;
		position: relative;
		overflow: hidden;
		&-container {
			position: absolute;
			display: flex;
			top: 0;
			bottom: 0;
			left: 0;
			transition: 200ms;
		}
		&-item {
			height: 100%;
			width: 100%;
			img {
				height: 100%;
			}
		}
		&-btn {
			z-index: 10;
			position: absolute;
			background: #0001;
			color: white;
			top: 50%;
			transform: translateY(-50%);
			padding: 50px 10px;
			font-size: 1.5rem;
			transition: 200ms;
			cursor: pointer;
			&-left {
				left: 0;
			}
			&-right {
				right: 0;
			}
		}
		&-btn:hover {
			box-shadow: 0 0 8px grey;
		}
	}
	&-data {
		&-download {
			&-links {
				background: $color-light-1;
				border-radius: 1rem;
				font-size: 0.85rem;
				max-width: none;
				@media (min-width: $sm-size) and (max-width: $lg-size) {
					max-width: 380px;
				}
				img {
					height: 2rem;
				}
			}
		}
	}
}
.product-card {
	width: 100%;
	height: 20rem;
	margin-bottom: 1.5rem;
	@media (max-width: $lg-size) {
		width: 18rem;
		height: 23rem;
	}
	@media (max-width: $md-size) {
		width: 16rem;
		height: 20rem;
	}
}
</style>