<template>
	<div>
		<header class="position-relative" id="header">
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>

		<main>
			<div class="container my-5">
				<h1 class="heading-1 text-center mb-5 text-uppercase">
					{{ $t("page.all-products") }}
				</h1>
				<loader
					v-if="loading"
					style="height: 50vh"
					class="d-flex justify-content-center align-content-center flex-column"
				></loader>
				<div class="row" v-else>
					<div
						class="col-lg-3 col-sm-6 col-12 mb-3"
						v-for="product in products"
						:key="product.id"
					>
						<product-card :data="product"> </product-card>
					</div>
					<div
						v-if="!products.length"
						class="fs-2 text-center text-uppercase text-secondary"
					>
						{{ $t("page.no-products") }}
					</div>
				</div>
			</div>
			<div class="container my-5" v-if="albums.length">
				<h1 class="heading-1 text-center mb-5 text-uppercase">
					{{ $t("page.our-gallery") }}
				</h1>
				<loader
					v-if="loading"
					style="height: 50vh"
					class="d-flex justify-content-center align-content-center flex-column"
				></loader>
				<div class="row" v-else>
					<div
						class="col-lg-3 col-md-4 col-sm-6 mb-lg-0 mb-4"
						v-for="card in albums"
						:key="card.id"
					>
						<gallery-card
							:title="card.title"
							:id="card.id"
							:image="card.primary_image"
						>
						</gallery-card>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { mapState } from "vuex";
import ProductCard from "../../components/ProductCard.vue";
import GalleryCard from "../../components/GalleryCard.vue";
import Loader from "../../components/Loader.vue";

export default {
	components: {
		ProductCard,
		GalleryCard,
		Loader,
	},
	computed: {
		...mapState({
			products: (state) => state.search.products,
			articels: (state) => state.search.articles,
			albums: (state) => state.search.albums,
		}),
	},
	data() {
		return {
			loading: true,
		};
	},
	watch: {
		$route() {
			this.fetch();
		},
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch("search/fetchAll", this.$route.query.text)
				.then()
				.finally(() => {
					this.loading = false;
				});
		},
	},
	created() {
		this.fetch();
	},
	//  TODO: remove //(comment) from next lines
	// async beforeRouteEnter(to, from, next) {
	//     await store.dispatch('products/fetchAll');
	//     next();
	// }
};
</script>

<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";

#header {
	background: linear-gradient(to left, #b8c2c6, #bac4c6);
	height: 220px;
	@media (min-width: $hd-size) {
		height: 300px;
	}
}
.product-card {
	width: 100%;
	height: 20rem;
	margin-bottom: 1.5rem;
	@media (max-width: $lg-size) {
		width: 18rem;
		height: 23rem;
	}
	@media (max-width: $md-size) {
		width: 16rem;
		height: 20rem;
	}
}
</style>