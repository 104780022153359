<template>
	<div>
		<header class="position-relative" id="header">
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>

		<main>
			<div class="container my-5">
				<breadcrumbs
					class="mb-4"
					:links="[
						{
							name: $t('navbar.home'),
							path: '/',
						},
						{ name: $t('navbar.categories'), path: '/categories' },
						{
							name: categorie.name,
							path: '/categories' + $route.params.id,
							active: true,
						},
					]"
				></breadcrumbs>
				<h1 class="heading-1 text-center mb-5 text-uppercase">
					{{ categorie.name }}
				</h1>
				<loader
					v-if="loading"
					style="height: 50vh"
					class="d-flex justify-content-center align-content-center flex-column"
				></loader>
				<div class="row" v-else>
					<div
						class="col-lg-3 col-sm-6 col-12 mb-3"
						v-for="product in categorie.products"
						:key="product.id"
					>
						<product-card
							:data="product"
							:links="{
								name: 'view-product',
								params: { id: product.id },
								query: { cate: categorie.id },
							}"
						>
						</product-card>
					</div>
					<div
						v-if="!categorie.products.length"
						class="fs-2 text-center text-uppercase text-secondary"
					>
						{{ $t("page.no-products") }}
					</div>
				</div>
			</div>
			<!-- <section class="container text-center">
				<h2 class="heading-2 text-uppercase mb-4">
					do you <br> need this serivce ?
				</h2>
				<p>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum praesentium repellat, adipisci rem molestiae unde dolorem impedit quisquam accusantium quidem quibusdam reprehenderit ipsum voluptas, eius assumenda! Nihil saepe libero nisi.
					Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia quasi ullam dolor libero quae sunt quisquam ducimus eveniet quibusdam dignissimos cumque, atque vero minima molestias, tenetur est. Tempore, aperiam nesciunt!
				</p>
			</section>

			<section class="parallax__group my-5">
				<div class="parallax__layer parallax__layer--to-right">
					<div class="container">
						<div class="parallax__content py-3">
							<h3 class="heading-3">reason 1</h3>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, in. Totam a possimus, quos corporis labore non nulla pariatur, harum dolor necessitatibus nobis voluptates rerum autem dolores consequuntur temporibus quas.
							</p>
						</div>
					</div>
				</div>
				<div class="parallax__layer parallax__layer--to-left">
					<div class="container">
						<div class="parallax__content">
							<h3 class="heading-3">reason 2</h3>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, in. Totam a possimus, quos corporis labore non nulla pariatur, harum dolor necessitatibus nobis voluptates rerum autem dolores consequuntur temporibus quas.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section class="container text-center mb-5">
				<div>
					<h2 class="text-uppercase">you also can</h2>
					<p>
						Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur in magni consectetur expedita maiores ratione eveniet quisquam minus impedit hic temporibus, assumenda unde aliquam quae quos magnam reprehenderit voluptas aut.
					</p>
				</div>

				<div class="row mb-md-5 mb-2">
					<div class="col-lg-1 col-2 d-sm-block d-none">
						<div class="side-image side-image--to-right"></div>
					</div>
					<div class="col">
						<h3 class="mb-1">step 1</h3>
						<p class="mb-0">
							Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias corporis nesciunt, sequi ea adipisci voluptatem eligendi, iste mollitia quasi deserunt aperiam reiciendis! Dolorem perspiciatis corrupti facilis atque corporis. Eum, molestias.
						</p>
					</div>
					<div class="col-lg-1 col-2 d-sm-block d-none">
						<div class="side-image side-image--to-left"></div>
					</div>
				</div>

				<div class="row mb-md-5 mb-2">
					<div class="col-lg-1 col-2 d-sm-block d-none">
						<div class="side-image side-image--to-right"></div>
					</div>
					<div class="col">
						<h3 class="mb-1">step 2</h3>
						<p class="mb-0">
							Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias corporis nesciunt, sequi ea adipisci voluptatem eligendi, iste mollitia quasi deserunt aperiam reiciendis! Dolorem perspiciatis corrupti facilis atque corporis. Eum, molestias.
						</p>
					</div>
					<div class="col-lg-1 col-2 d-sm-block d-none">
						<div class="side-image side-image--to-left"></div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-1 col-2 d-sm-block d-none">
						<div class="side-image side-image--to-right"></div>
					</div>
					<div class="col">
						<h3 class="mb-1">step 3</h3>
						<p class="mb-0">
							Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias corporis nesciunt, sequi ea adipisci voluptatem eligendi, iste mollitia quasi deserunt aperiam reiciendis! Dolorem perspiciatis corrupti facilis atque corporis. Eum, molestias.
						</p>
					</div>
					<div class="col-lg-1 col-2 d-sm-block d-none">
						<div class="side-image side-image--to-left"></div>
					</div>
				</div>
			</section>

			<section class="container pb-5">
				<h2 class="heading-2 text-uppercase text-center mb-4">
					Choose you plan
				</h2>
				<div class="row">
					<div class="col mb-lg-0 mb-3 text-center">
						<div class="flip-card">
							<div class="flip-card__front">
								<h3 class="heading-3">
									quick <br> plan
								</h3>
							</div>
							<div class="flip-card__back">
								<div>
									<p class="mb-md-3 mb-2">
										Put your details here
									</p>
									<button class="button button--outline-light">Take it</button>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col mb-lg-0 mb-3 text-center">
						<div class="flip-card">
							<div class="flip-card__front">
								<h3 class="heading-3">
									common <br> plan
								</h3>
							</div>
							<div class="flip-card__back">
								<div>
									<p class="mb-md-3 mb-2">
										Put your details here
									</p>
									<button class="button button--outline-light">Take it</button>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col mb-lg-0 mb-3 text-center">
						<div class="flip-card">
							<div class="flip-card__front">
								<h3 class="heading-3">
									custem <br> plan
								</h3>
							</div>
							<div class="flip-card__back">
								<div>
									<p class="mb-md-3 mb-2">
										Put your details here
									</p>
									<button class="button button--outline-light">Take it</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> -->
		</main>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import store from "../../store";
import Loader from "../../components/Loader.vue";
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import ProductCard from "../../components/ProductCard.vue";

export default {
	data: () => ({
		loading: true,
		categorie: {},
	}),
	components: {
		Loader,
		Breadcrumbs,
		ProductCard,
	},
	computed: {
		// ...mapGetters({
		// 	getCategoryById: "categories/getById",
		// }),
		// ...mapState({
		// 	categories: (state) => state.categories.categories,
		// }),
		// category() {
		// 	return this.getCategoryById(parseInt(this.$route.params.id));
		// },
	},
	watch: {
		"$route.params"() {
			this.fetchCategorie();
		},
	},
	methods: {
		fetchCategorie() {
			this.loading = true;
			this.$store
				.dispatch("categories/fetchById", { id: this.$route.params.id })
				.then((data) => {
					this.categorie = data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.fetchCategorie();
	},
	//  TODO: remove //(comment) from next lines
	// async beforeRouteEnter(to, from, next) {
	//     await store.dispatch('categories/fetch', { id: to.params.id });
	//     next();
	// }
};
</script>
<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";

#header {
	background: linear-gradient(to left, #b8c2c6, #bac4c6);
	height: 220px;
	@media (min-width: $hd-size) {
		height: 300px;
	}
}
.product-card {
	width: 100%;
	height: 20rem;
	margin-bottom: 1.5rem;
	@media (max-width: $lg-size) {
		width: 18rem;
		height: 23rem;
	}
	@media (max-width: $md-size) {
		width: 16rem;
		height: 20rem;
	}
}
</style>