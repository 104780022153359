<template>
	<div>
		<header class="position-relative" id="header">
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>
		<loader
			v-if="loading"
			style="height: 50vh"
			class="d-flex justify-content-center align-content-center flex-column"
		></loader>
		<main v-else>
			<section>
				<div class="container">
					<breadcrumbs
						class="mb-4 text-start"
						:links="[
							{
								name: $t('navbar.home'),
								path: '/',
							},
							{ name: $t('navbar.gallery'), path: '/albums' },
							{ name: album.title, path: '/albums/' + album.id, active: true },
						]"
					></breadcrumbs>
					<h2 class="heading-2 text-center mb-4">{{ album.title }}</h2>
					<cool-light-box
						:items="album.images"
						:index="index"
						:useZoomBar="true"
						:fullScreen="true"
						@close="index = null"
					></cool-light-box>

					<div class="images-wrapper">
						<div
							class="image"
							v-for="(image, imageIndex) in album.images"
							:key="imageIndex"
							@click="index = imageIndex"
							:style="{ backgroundImage: 'url(' + image + ')' }"
						></div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CoolLightBox from "vue-cool-lightbox";
import store from "../../store";
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import Loader from "../../components/Loader.vue";
export default {
	data() {
		return {
			galleries: [
				{
					title: "gallery 1",
					text: "Lorem 1 ipsum dolor sit amet consectetur adipisicing elit. Vel rem maxime est dolorem dolorum dicta vero,Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint minus dignissimos perspiciatis ab maiores repellat incidunt totam modi ea saepe. Eos sint quos excepturi magni possimus maiores impedit repellendus sed. reiciendis ad odio qui Lorem ipsum dolor",
				},
				{
					title: "gallery 2",
					text: "Lorem 2 ipsum dolor sit amet consectetur adipisicing elit. Vel rem maxime est dolorem dolorum dicta vero, ab maiores repellat incidunt totam modi ea saepe. Eos sint quos excepturi magni possimus maiores impedit repellendus sed. reiciendis ad odio qui Lorem ipsum dolor",
				},
				{
					title: "gallery 3",
					text: "Lorem 3 ipsum dolor sit amet consectetur adipisicing elit. Vel rem maxime est dolorem dolorum dicta vero, int quos excepturi magni possimus maiores impedit repellendus sed. reiciendis ad odio qui Lorem ipsum dolor",
				},
				{
					title: "gallery 4",
					text: "Lorem 4 ipsum dolor sit amet consectetur adipisicing elit. Vel rem maxime est dolorem dolorum dicta vero, reiciendis ad odio qui Lorem ipsum dolor",
				},
			],

			items: [
				{
					title: "image 1",
					description: "Photo by National Cancer Institute",
					src: "https://images.unsplash.com/photo-1579684385127-1ef15d508118?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=880&q=80",
				},
				{
					title: "image 2",
					description: "Photo by CDC",
					src: "https://images.unsplash.com/photo-1576765608535-5f04d1e3f289?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1178&q=80",
				},
				{
					title: "image 3",
					description: "Photo by Ani Kolleshi",
					src: "https://images.unsplash.com/photo-1527613426441-4da17471b66d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80",
				},
				{
					title: "image 4",
					description: "Photo by Solen Feyissa",
					src: "https://images.unsplash.com/photo-1596541223130-5d31a73fb6c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1171&q=80",
				},
				{
					title: "image 5",
					description: "Photo by Tom Claes",
					src: "https://images.unsplash.com/photo-1597764690523-15bea4c581c9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
				},
				{
					title: "image 6",
					description: "Photo by Jonathan Borba",
					src: "https://images.unsplash.com/photo-1588776814601-a454a8e3a940?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80",
				},
				{
					title: "image 7",
					description: "Photo by Viki Mohamad",
					src: "https://images.unsplash.com/photo-1528218635780-5952720c9729?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1176&q=80",
				},
				{
					title: "image 8",
					description: "Photo by Viki Mohamad",
					src: "https://mynursingbag.com/wp-content/uploads/2018/12/rsw-1280.jpg",
				},
				{
					title: "image 9",
					description: "Photo by Viki",
					src: "/img/gallery1/cover.jpg",
				},
				{
					title: "image 10",
					description: "Photo by Viki",
					src: "/img/gallery2/cover.jpg",
				},
				{
					title: "image 11",
					description: "Photo by Viki",
					src: "/img/gallery3/cover.jpg",
				},
				{
					title: "image 12",
					description: "Photo by Viki",
					src: "/img/gallery4/cover.jpg",
				},
			],
			loading: true,
			index: null,
		};
	},

	components: {
		CoolLightBox,
		Breadcrumbs,
		Loader,
	},

	computed: {
		...mapGetters({
			getAlbumById: "albums/getById",
		}),

		album() {
			return this.getAlbumById(parseInt(this.$route.params.id));
		},
	},
	methods: {
		fetchAlbum() {
			this.$store
				.dispatch("albums/fetchAll")
				.then()
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.fetchAlbum();
	},
	//  TODO: remove //(comment) from next lines
	// async beforeRouteEnter(to, from, next) {
	//     await store.dispatch('albums/fetch', { id: to.params.id });
	//     next();
	// }
};
</script>

<style lang="scss" scoped>
@import "../../scss/shared-vue.scss";
.images-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.image {
	width: 200px;
	height: 200px;
	margin: 0 10px;
	margin-bottom: 1rem;
	border-radius: 1.2rem;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	transition: all 0.3s;
	cursor: pointer;

	&:hover {
		transform: translateY(-10px);
		box-shadow: 0 0.75rem 2rem rgba($color-dark, 0.3);
	}

	@include touch-remove-hov {
		&:hover {
			transform: none;
			box-shadow: none;
		}
	}
}

#header {
	background: linear-gradient(to left, #b8c2c6, #bac4c6);
	height: 220px;
	@media (min-width: $hd-size) {
		height: 300px;
	}
}
</style>